import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';

import { LoadingTable } from '@amzn/imdb-shared-meridian-components/components/DataTable/LoadingTable/LoadingTable';
import Alert from '@amzn/meridian/alert';
import Heading from '@amzn/meridian/heading';
import Row from '@amzn/meridian/row';

import { ListsUserSummary } from '../../../../../trustAdmin-api/generated-src/api';
import TrustAdminApiFactory from '../../../../../trustAdmin-api/TrustAdminApiFactory';
import { RequestErrorCard } from '../../../../features/error/RequestErrorCard';
import ListSpamWorkflowDataTable from '../../../../features/ListSpamWorkflowDataTable/ListSpamWorkflowDataTable';
import { ListSpamWorkflowErrorTable } from '../../../../features/ListSpamWorkflowDataTable/ListSpamWorkflowErrorTable';

export interface ListSpamWorkflowResponseData {
    customerId: string;
}

export interface ListSpamWorkflowError {
    id: string;
    code: string;
    message: string;
}

export const ListSpamWorkflowResults: React.FC = () => {
    const { state } = useLocation();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [data, setData] = useState<ListsUserSummary[]>([]);
    const [errorIds, setErrorIds] = useState<ListSpamWorkflowError[]>([]);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        if (!state?.listsOwners) {
            setError(new Error('No data given in location state'));
            return;
        }

        async function convertDataToRows() {
            setLoading(true);
            setMessage('Fetching users data');

            try {
                if (state.listsOwners.customerIds?.length > 0) {
                    const response = await TrustAdminApiFactory().bulkGetListsUserSummary.bulkGetListsUserSummary({
                        ids: state.listsOwners.customerIds
                    });
                    if (response.data) {
                        setData(response.data);
                        setMessage('Successfully fetched users data');
                    }
                }
                if (state.listsOwners.errors.length > 0) {
                    setErrorIds(state.listsOwners.errors);
                }
            } catch (e: any) {
                setError(e);
                setMessage('');
            } finally {
                setLoading(false);
            }
        }

        convertDataToRows();
    }, [state]);

    if (error) {
        return <RequestErrorCard error={error} />;
    }

    return (
        <>
            {message && <Alert type={loading ? 'informational' : 'success'}>{message}</Alert>}
            <Heading level={1}>List Spam Workflow Search Results</Heading>
            <Row spacingInset='300' backgroundColor='secondary'></Row>
            {loading && <LoadingTable />}
            {!loading && data?.length > 0 && <ListSpamWorkflowDataTable users={data} />}
            {state?.listsOwners?.errors?.length > 0 && (
                <>
                    <Heading level={2}>Errors</Heading>
                    <ListSpamWorkflowErrorTable responseErrors={errorIds} />
                </>
            )}
        </>
    );
};
