export interface IAppSettings {
    apiUrl: string;
    stage: string;
    listORamaLink: string;
}

let cache = {};

export function getAppSetting(key: string) {
    return cache[key];
}

export function initializeAppSettings(settings: IAppSettings) {
    cache = { ...settings };
    Object.freeze(cache);
}

const LISTORAMA_LINK = 'listorama-admin.imdb.amazon.dev';

export const getListORamaLink = () => {
    const stage = getAppSetting('stage')?.toLowerCase() == 'prod' ? 'prod' : 'beta';
    return `https://${stage}.${LISTORAMA_LINK}`;
};
