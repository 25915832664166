import React from 'react';
import { useNavigate, useLocation } from 'react-router';

import arrowLeftLargeTokens from '@amzn/meridian-tokens/base/icon/arrow-left-large';
import Button from '@amzn/meridian/button';
import Heading from '@amzn/meridian/heading';
import Icon from '@amzn/meridian/icon';

import { BulkShadowbanForm } from '../../../features/BulkShadowbanForm/BulkShadowbanForm';

export const BulkShadowbanCreate: React.VFC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleBackClick = () => {
        if (location.key === 'default') {
            navigate('/bulkShadowban', { replace: true });
        } else {
            navigate(-1);
        }
    };

    return (
        <>
            <Button onClick={handleBackClick}>
                <Icon tokens={arrowLeftLargeTokens} />
            </Button>
            <Heading level={1}>Create Bulk Shadowban Request</Heading>
            <BulkShadowbanForm />
        </>
    );
};
