import React from 'react';

import { getListORamaLink } from '../../../../config/AppSettings';
import { UserAttributes } from '../../../../trustAdmin-api/generated-src/api';
import { CustomCell as Cell, TextLink, CustomText as Text } from './CustomComponents';

export const TEST_IDS = {
    USER_IS_PRO: 'user-is-pro'
};

const UserAttributesCells: React.FC<UserAttributes> = ({ nickName, status, registeredDay, isPro, customerId }) => (
    <>
        <Cell width={50}>
            <TextLink href={getListUserInfoLink(customerId)}>
                <Text>{nickName}</Text>
            </TextLink>
        </Cell>
        <Cell width={50}>{status}</Cell>
        <Cell width={50}>{registeredDay}</Cell>
        <Cell data-testid={TEST_IDS.USER_IS_PRO} width={50}>
            {isPro ? 'Yes' : 'No'}
        </Cell>
    </>
);

export const getListUserInfoLink = (customerId?: string) =>
    customerId ? `${getListORamaLink()}/userDetails?authorId=${customerId}` : '#';

export default UserAttributesCells;
