import React, { ReactNode } from 'react';

import Column, { ColumnProps } from '@amzn/meridian/column/column';
import Link, { LinkProps } from '@amzn/meridian/link/link';
import Row, { RowProps } from '@amzn/meridian/row/row';
import TableCell, { TableCellProps } from '@amzn/meridian/table/table-cell';
import Text, { TextProps } from '@amzn/meridian/text/text';

export const COLUMN_MAX_WIDTH = 150;
export const ROW_MAX_WIDTH = COLUMN_MAX_WIDTH * 2;

interface CustomCellProps extends TableCellProps {
    children?: ReactNode;
}

export const CustomCell = ({ children, ...props }: CustomCellProps) => {
    return (
        <TableCell alignmentVertical='top' {...props} width={props.width ?? ROW_MAX_WIDTH}>
            {children ?? ''}
        </TableCell>
    );
};

type CustomTextProps = TextProps;
export const CustomText = ({ children, ...props }: CustomTextProps) => (
    <Text breakWord={true} truncate={true} {...props}>
        {children}
    </Text>
);

type TextLinkProps = LinkProps;
export const TextLink = ({ children, ...props }: TextLinkProps) => (
    <Link target='_blank' {...props}>
        {children}
    </Link>
);

export type ListColumnProps = ColumnProps;
export const ListColumn = ({ children, ...columnProps }: ListColumnProps) => {
    return (
        <Column width={COLUMN_MAX_WIDTH} {...columnProps}>
            {children}
        </Column>
    );
};

export type ContainerProps = RowProps;
export const Container = ({ children, ...rowProps }: ContainerProps) => (
    <Row maxWidth={ROW_MAX_WIDTH} widths={rowProps.widths ?? ['grid-6', 'grid-6']} wrap='down' {...rowProps}>
        {children}
    </Row>
);
