import { useNavigate } from 'react-router-dom';

import { BulkUserStatusUpdaterRequest, FeatureType } from '../trustAdmin-api/generated-src';

export const BULK_SHADOWBAN_CREATE = '/bulkShadowban/create/';

/**
 * Map of feature_type value to key.
 * We will need to use FeatureType key as input in BulkShadowbanForm feature
 * For example: { user_lists = 'UserLists',....}
 */
export const FEATURE_TYPE_TO_KEY_MAP = Object.fromEntries(
    Object.entries(FeatureType).map(([key, value]) => [value, key])
);

const useBulkUserStatusUpdaterRequest = () => {
    const navigate = useNavigate();

    const createBulkUserStatusUpdaterRequest = (request: BulkUserStatusUpdaterRequest) => {
        navigate(BULK_SHADOWBAN_CREATE, {
            state: {
                ...request,
                feature: FEATURE_TYPE_TO_KEY_MAP[request.feature]
            }
        });
    };

    return { createBulkUserStatusUpdaterRequest };
};

export default useBulkUserStatusUpdaterRequest;
