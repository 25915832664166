import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import Column from '@amzn/meridian/column';
import Divider from '@amzn/meridian/divider';

import { ListsUserSummary } from '../../../../trustAdmin-api/generated-src/api';
import { CustomCell as Cell, Container, CustomText as Text } from './CustomComponents';

interface BigramsCellsProps {
    bigrams?: ListsUserSummary['bigrams'];
}

export const BIGRAMS_CONTAINER_TEST_ID = 'bigrams-container';

const BigramsCells: React.FC<BigramsCellsProps> = ({ bigrams = [] }) => (
    <Cell>
        <Container key='bigram-header'>
            <Column>
                <b>Bigram</b>
            </Column>
            <Column>
                <b>Count</b>
            </Column>
        </Container>
        <Divider />
        {bigrams?.length > 0 &&
            bigrams?.map((bigram) => (
                <React.Fragment key={`bigram-${uuidv4()}`}>
                    <Divider />
                    <Container data-testid={BIGRAMS_CONTAINER_TEST_ID}>
                        <Column>
                            <Text>{bigram.value ?? ''}</Text>
                        </Column>
                        <Column>
                            <Text>{bigram.count ?? ''}</Text>
                        </Column>
                    </Container>
                </React.Fragment>
            ))}
    </Cell>
);

export default BigramsCells;
