import React, { useState, useEffect } from 'react';

import Button from '@amzn/meridian/button';
import Checkbox from '@amzn/meridian/checkbox';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Row from '@amzn/meridian/row';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import Text from '@amzn/meridian/text';

import useBulkUserStatusUpdaterRequest from '../../../hooks/useBulkUserStatusUpdaterRequest';
import { ListsUserSummary, FeatureType } from '../../../trustAdmin-api/generated-src/api';
import BigramCells from './components/Bigrams';
import ListDistributionCells from './components/ListDistribution';
import RecentLists from './components/RecentLists';
import UserAttributesCells from './components/UserAttributes';

export const IDS = {
    ROW: 'list-spam-workflow-data-table-row',
    CHECKBOX: 'list-spam-workflow-data-table-checkbox',
    CHECKBOX_ALL: 'list-spam-workflow-data-table-checkbox-all',
    BAN_BUTTON: 'list-spam-workflow-data-table-ban-button',
    CONFIRMATION_MODAL: 'list-spam-workflow-data-table-confirmation-modal',
    CONFIRMATION_MODAL_CLOSE: 'list-spam-workflow-data-table-confirmation-modal-close',
    CONFIRMATION_MODAL_CONFIRM: 'list-spam-workflow-data-table-confirmation-modal-confirm'
};

export const SHADOWBAN_CONTEXT = 'List Abuser';

/**
 *  Returns the confirmation text based on the number of users selected.
 *  Case where count = 0 should never be reached because the ban button is disabled if no user selected.
 *  @param count The number of users selected.
 */
export const getConfirmationText = (count: number) => {
    switch (count) {
        case 0:
            return 'Please select at least one user to shadowban.';
        case 1:
            return 'Are you sure you want to shadowban the selected user?';
        default:
            return `Are you sure you want to shadowban ${count} selected users?`;
    }
};

const setAllKeys = (object, value) => {
    for (const key in object) object[key] = value;
    return object;
};

type ListSpamWorkflowDataTableProps = {
    users: ListsUserSummary[];
};

export default function ListSpamWorkflowDataTable({ users }: ListSpamWorkflowDataTableProps) {
    const { createBulkUserStatusUpdaterRequest } = useBulkUserStatusUpdaterRequest();

    const [open, setOpen] = useState(false);

    const [selected, setSelected] = useState({});

    const count = Object.keys(selected).length;
    const checkedCount = Object.values(selected as any).filter(Boolean).length;
    const allSelected = count === checkedCount;

    const onChangeRow = (id) => (value) => setSelected({ ...selected, [id]: value });

    const onChangeHeader = () => {
        const allValues = allSelected ? false : true;
        const nextSelected = { ...setAllKeys(selected, allValues) };
        setSelected(nextSelected);
    };

    const banUsers = () => {
        const customerIds = Object.keys(selected).filter((key) => selected[key]);
        createBulkUserStatusUpdaterRequest({
            customerIds,
            feature: FeatureType.UserLists,
            shadowbanContext: SHADOWBAN_CONTEXT
        });
    };

    const closeModal = () => {
        setOpen(false);
    };

    const confirmShadowban = () => {
        setOpen(false);
        banUsers();
    };

    useEffect(() => {
        let cancel = false;
        if (!cancel) {
            const userObject = Object.fromEntries(users.map((user) => [user.userAttributes.customerId, false]));
            setSelected(setAllKeys(userObject, false));
        }
        return () => {
            cancel = true;
        };
    }, []);

    return (
        <>
            {open && (
                <Modal
                    title={'Bulk Shadowban Confirmation'}
                    open={open}
                    onClose={closeModal}
                    scrollContainer='viewport'
                    closeLabel='Close'
                    aria-describedby='modal-description'
                    data-testid={IDS.CONFIRMATION_MODAL}
                >
                    <Text id='modal-description'>{getConfirmationText(checkedCount)}</Text>
                    <ModalFooter>
                        <Row alignmentHorizontal='end' widths='fit'>
                            <Button
                                type='secondary'
                                size='small'
                                onClick={closeModal}
                                data-testid={IDS.CONFIRMATION_MODAL_CLOSE}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='primary'
                                size='small'
                                onClick={confirmShadowban}
                                disabled={checkedCount === 0}
                                data-testid={IDS.CONFIRMATION_MODAL_CONFIRM}
                            >
                                Confirm
                            </Button>
                        </Row>
                    </ModalFooter>
                </Modal>
            )}
            <Table showDividers={true} showStripes={true} headerRows={2} stickyHeaderRow={true}>
                <TableRow>
                    <TableCell>{/**Empty Cell for Checkbox */}</TableCell>
                    <TableCell columnSpan={4}>User Info</TableCell>
                    <TableCell columnSpan={4}>List Distribution</TableCell>
                    <TableCell>Recent Lists</TableCell>
                    <TableCell>Bigrams</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>
                        <Checkbox
                            checked={checkedCount > 0 ? (allSelected ? true : 'mixed') : false}
                            onChange={onChangeHeader}
                            data-testid={IDS.CHECKBOX_ALL}
                        />
                    </TableCell>
                    <TableCell>Nickname</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Registered Day</TableCell>
                    <TableCell>Is Pro</TableCell>
                    <TableCell>Creation velocity (lists/day)</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>Publicly Visible</TableCell>
                    <TableCell>Restricted Lists</TableCell>
                    <TableCell>Most 10 recent lits</TableCell>
                    <TableCell>Top 10 bigrams</TableCell>
                </TableRow>

                {users.map((user) => {
                    const customerId = user.userAttributes.customerId;
                    return (
                        <TableRow
                            key={`${IDS.ROW}-${customerId}`}
                            highlightOnHover={true}
                            data-testid={`${IDS.ROW}-${customerId}`}
                        >
                            <TableCell>
                                <Checkbox
                                    checked={selected[customerId]}
                                    onChange={onChangeRow(customerId)}
                                    data-testid={`${IDS.CHECKBOX}-${customerId}`}
                                />
                            </TableCell>
                            <UserAttributesCells {...user.userAttributes} />
                            <ListDistributionCells {...user.listsCreated} />
                            <RecentLists {...user.listsCreated} />
                            <BigramCells key={`bigrams-${customerId}`} {...user} />
                        </TableRow>
                    );
                })}
            </Table>

            <Row spacingInset='300' backgroundColor='secondary'>
                <Button disabled={checkedCount === 0} onClick={() => setOpen(true)} data-testid={IDS.BAN_BUTTON}>
                    Ban Selected Users
                </Button>
            </Row>
        </>
    );
}
