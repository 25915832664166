import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import Divider from '@amzn/meridian/divider';

import { ListsUserSummaryListsCreated, CreatedListsDistribution } from '../../../../trustAdmin-api/generated-src/api';
import { Container, CustomCell as Cell, CustomText as Text, ListColumn } from './CustomComponents';

const ListDistributionCells: React.FC<ListsUserSummaryListsCreated> = ({
    listsCreationVelocity,
    maxVelocity,
    overall
}) => (
    <>
        <Velocity listsCreationVelocity={listsCreationVelocity} maxVelocity={maxVelocity} />
        <Cell width={50}>{overall?.total ?? ''}</Cell>
        <Cell width={50}>{overall?.publicCount ?? ''}</Cell>
        <Cell width={50}>{overall?.restrictedCount ?? ''}</Cell>
    </>
);

type VelocityProps = Pick<ListsUserSummaryListsCreated, 'listsCreationVelocity' | 'maxVelocity'>;

const Velocity: React.FC<VelocityProps> = ({ listsCreationVelocity, maxVelocity }) => {
    if (listsCreationVelocity?.length === 0) {
        return <Cell>No lists created</Cell>;
    }
    return (
        <Cell>
            <Container>
                <ListColumn>
                    <b>Description</b>
                </ListColumn>
                <ListColumn>
                    <b>Velocity</b>
                </ListColumn>
            </Container>
            <Divider />

            {maxVelocity && <VelocityRow velocity={maxVelocity} description='Max Velocity' key='velocity-max' />}

            {listsCreationVelocity?.map((velocity, index) => (
                <div key={`velocity-${uuidv4()}`}>
                    <Divider />
                    <VelocityRow velocity={velocity} description={index == 0 ? 'List Distribution' : ''} />
                </div>
            ))}
        </Cell>
    );
};

interface VelocityRowProps {
    velocity: CreatedListsDistribution;

    description?: string;
}

const VelocityRow = ({ velocity, description }: VelocityRowProps) => (
    <Container>
        <ListColumn>
            <Text>{description ?? ''}</Text>
        </ListColumn>
        <ListColumn>
            <Text>
                <b>{velocity.count}</b> on <b>{velocity.createdDay}</b>
            </Text>
        </ListColumn>
    </Container>
);

export default ListDistributionCells;
