import React from 'react';

import Divider from '@amzn/meridian/divider';

import { getListORamaLink } from '../../../../config/AppSettings';
import { ListsUserSummaryListsCreated } from '../../../../trustAdmin-api/generated-src/api';
import { ListColumn, Container, CustomCell as Cell, CustomText as Text, TextLink } from './CustomComponents';

export const NO_LISTS_CREATED = 'No lists created';

export interface RecentListsProps {
    lists?: ListsUserSummaryListsCreated['lists'];
}

const RecentLists: React.FC<RecentListsProps> = ({ lists = [] }) => {
    if (lists?.length === 0) {
        return <Cell>{NO_LISTS_CREATED}</Cell>;
    }
    return (
        <Cell>
            <Container>
                <ListColumn>
                    <b>List Name</b>
                </ListColumn>
                <ListColumn>
                    <b>List Description</b>
                </ListColumn>
            </Container>
            {lists?.length > 0 &&
                lists?.map((list) => (
                    <React.Fragment key={list.id}>
                        <Divider />
                        <Container>
                            <ListColumn>
                                <TextLink href={getListLink(list.id)}>
                                    <Text> {list.name ?? ''} </Text>
                                </TextLink>
                            </ListColumn>
                            <ListColumn>
                                <Text>{list.description ?? ''}</Text>
                            </ListColumn>
                        </Container>
                    </React.Fragment>
                ))}
        </Cell>
    );
};

export const getListLink = (listId?: string) => (listId ? `${getListORamaLink()}/listDetails?listId=${listId}` : '#');

export default RecentLists;
